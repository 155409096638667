import Web3 from "web3";

declare global {
    interface Window {
        ethereum?: any;
        web3?: any;
    }
}

export const getHttpProvider = async () => new Promise<Web3>(async (resolve, reject) => {
    let web3Provider;

    // Initialize web3 (https://medium.com/coinmonks/web3-js-ethereum-javascript-api-72f7b22e2f0a)
    // Modern dApp browsers...
    if (window.ethereum) {
      web3Provider = window.ethereum;
      try {
        // Request account access
        await window.ethereum.enable();
      } catch (error) {
        // User denied account access...
        reject("Please allow access to your Web3 wallet.");
        return;
      }
    }
    // Legacy dApp browsers...
    else if (window.web3) {
      web3Provider = window.web3.currentProvider;
    }
    // If no injected web3 instance is detected, fall back to Ganache
    else {
      reject("Please install MetaMask!");
      return;
    }

    resolve(new Web3(web3Provider));
});