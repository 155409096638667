import React, { Component } from 'react'
import { AppState } from './App';
import { ContractType } from "../lib/contracts";
import GatewayJS from "@renproject/gateway";
import { Contract } from "web3-eth-contract";
import { Token } from "../lib/token"
import Swapper from "./Swapper";
import { Blob } from "react-blob";
import { ReactComponent as IconDittoBTC } from "../styles/images/dittobtc.svg";

const PendingApprovalText = "Waiting for approval";
const PendingSubmissionText = "Waiting for submission";

interface Props {
    web3: AppState["web3"];
    contracts: AppState["contracts"];
    gateway: AppState["gateway"];
    address: AppState["address"];
}

interface State {
    errorMsg: string;
    modalText: string;
    height: number; // Used for calculating background height.
}

class Transform extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            errorMsg: "",
            modalText: "",
            height: 0,
        }
    }

    componentDidMount() {
        this.setState({ height: document.body.scrollHeight });
    }

    render() {
        const { web3, contracts } = this.props;
        const { errorMsg, modalText, height } = this.state;
        return (
            <div className="transform">
                <div className="background" style={{ height }}></div>
                {modalText &&
                    <div className="modal">
                        <div className="content">
                            <Blob className="blob" size="250px">{modalText}</Blob>
                        </div>
                    </div>
                }
                <div className="container">
                    <div className="swapper">
                        <Swapper disabled={!web3} contracts={contracts} onSwap={this.swap} resetError={() => this.setState({ errorMsg: "" })} />
                        {errorMsg !== "" &&
                            <p className="error">{errorMsg}</p>
                        }
                    </div>

                    <div className="about">
                        <div className="info">
                            <h2>Enabling a seamless DeFi experience</h2>
                            <p>Ditto helps combine liquidity, features, and security across multiple asset forms. Ditto allows you to trustlessly swap your variants between one another, with a fixed fee, delivering incentives for providing liquidity and rebalancing along the way.</p>
                        </div>
                        <div className="token">
                            <h2><IconDittoBTC />Introducing dittoBTC</h2>
                            <p>Ditto Bitcoin (or dittoBTC for short) is a flexible, multi-collateral Bitcoin variant backed by reputable variants such as wBTC and renBTC. This allows you to diversify your risk while making use of Bitcoin on Ethereum.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    swap = async (sendToken: Token, receiveToken: Token, amount: string, receiveBtcAddress: string) => {
        const { contracts } = this.props;
        if (!contracts) {
            this.setState({ errorMsg: "Unable to connect, please check your connection" });
            return;
        }

        try {
            this.setState({ errorMsg: "" });

            const renBTC = contracts.get(ContractType.RenBTC);
            const wBTC = contracts.get(ContractType.WBTC);
            const dittoBTC = contracts.get(ContractType.DittoBTC);
            const renAdapter = contracts.get(ContractType.RenAdapter);
            const controller = contracts.get(ContractType.Controller);
            const transformer = contracts.get(ContractType.Transformer);
            const reserve = contracts.get(ContractType.Reserve);
            if (!wBTC || !dittoBTC || !renBTC || !renAdapter || !controller || !transformer || !reserve) {
                this.setState({ errorMsg: "Failed to connect to contracts, please check your connection" });
                return;
            }

            const value = Math.floor(parseFloat(amount) * (10 ** 8));

            if (receiveToken === Token.BTC && receiveBtcAddress === "") {
                this.setState({ errorMsg: "Please enter a valid Bitcoin address to receive your funds" })
                return;
            }

            if (sendToken === receiveToken) {
                await this.transfer(wBTC, renBTC, dittoBTC, receiveBtcAddress, value, receiveToken);
                return;
            }

            if (sendToken === Token.DittoBTC) {
                await this.withdraw(renAdapter, dittoBTC, transformer, wBTC, renBTC, receiveBtcAddress, value, receiveToken);
                return;
            }

            if (receiveToken === Token.DittoBTC) {
                await this.deposit(renAdapter, controller, transformer, wBTC, renBTC, value, sendToken);
                return;
            }

            if (receiveToken === Token.BTC && sendToken === Token.WBTC) {
                await this.swapWBTCToBTC(renBTC, reserve, wBTC, renAdapter, receiveBtcAddress, value);
            } else if (receiveToken === Token.WBTC && sendToken === Token.BTC) {
                await this.swapBTCToWBTC(renBTC, reserve, wBTC, renAdapter, value);
            } else if (receiveToken === Token.WBTC && sendToken === Token.RenBTC) {
                await this.swapERC20(renBTC, wBTC, reserve, transformer, value);
            } else if (receiveToken === Token.RenBTC && sendToken === Token.WBTC) {
                await this.swapERC20(wBTC, renBTC, reserve, transformer, value);
            } else {
                this.setState({ errorMsg: `Unsupported coin pair ${receiveToken} -> ${sendToken}` });
            }

        } catch (err) {
            console.error(err);
            this.setState({ modalText: "" });
        }
    }


    swapERC20 = async (fromTokenForm: Contract, toTokenForm: Contract, reserve: Contract, transformer: Contract, amount: number) => {
        const { address } = this.props;

        const balance = await reserve.methods.btcBalance(toTokenForm.options.address).call();
        if (balance < amount) {
            this.setState({ errorMsg: `Maximum available liquidity is ${balance}` });
            return;
        }

        const allowance = await fromTokenForm.methods.allowance(address, reserve.options.address).call();
        if (allowance < amount) {
            this.setState({ modalText: PendingApprovalText });
            await fromTokenForm.methods.approve(reserve.options.address, amount.toString()).send({ from: address });
        }


        await transformer.methods.transform(fromTokenForm, toTokenForm, amount).send({ from: address });
    }

    swapWBTCToBTC = async (renBTC: Contract, reserve: Contract, wBTC: Contract, renAdapter: Contract, toAddress: string, amount: number) => {
        const { web3, gateway, address } = this.props;
        if (!web3 || !gateway) {
            this.setState({ errorMsg: "Please enable Web3 or download the MetaMask extension" });
            return;
        }

        const balance = await reserve.methods.btcBalance(renBTC.options.address).call();
        if (balance < amount) {
            this.setState({ errorMsg: `Maximum available liquidity is ${balance} BTC` });
            return;
        }

        const allowance = await wBTC.methods.allowance(address, renAdapter.options.address).call();
        if (allowance < amount) {
            this.setState({ modalText: PendingApprovalText });
            await wBTC.methods.approve(renAdapter.options.address, amount.toString()).send({ from: address });
        }

        await gateway.open({
            // Send BTC from the Ethereum blockchain to the Bitcoin blockchain.
            // This is the reverse of shitIn.
            sendToken: GatewayJS.Tokens.BTC.Eth2Btc,

            // The contract we want to interact with
            sendTo: renAdapter.options.address,

            // The name of the function we want to call
            contractFn: "swapOut",

            // Arguments expected for calling `deposit`
            contractParams: [
                { name: "_tokenSymbol", type: "string", value: "BTC" },
                { name: "_fromBtcVariant", type: "address", value: wBTC.options.address },
                { name: "_to", type: "bytes", value: "0x" + Buffer.from(toAddress).toString("hex") },
                { name: "_amount", type: "uint256", value: Math.floor(amount) },
            ],

            // Web3 provider for submitting burn to Ethereum
            web3Provider: web3,
        }).result();

        this.log(`Transferred ${amount} BTC to ${toAddress}.`);
    }

    swapBTCToWBTC = async (renBTC: Contract, reserve: Contract, wBTC: Contract, renAdapter: Contract, amount: number) => {
        const { web3, gateway } = this.props;
        if (!web3 || !gateway) {
            this.setState({ errorMsg: "Please enable Web3 or download the MetaMask extension" });
            return;
        }

        const balance = await reserve.methods.btcBalance(wBTC.options.address).call();
        if (balance < amount) {
            this.setState({ errorMsg: `Maximum available liquidity is ${balance} WBTC` });
            return;
        }

        try {
            await gateway.open({
                // Send BTC from the Bitcoin blockchain to the Ethereum blockchain.
                sendToken: GatewayJS.Tokens.BTC.Btc2Eth,

                // Amount of BTC we are sending (in Satoshis)
                suggestedAmount: Math.floor(amount), // Convert to Satoshis

                // The contract we want to interact with
                sendTo: renAdapter.options.address,

                // The name of the function we want to call
                contractFn: "swapIn",

                // The nonce is used to guarantee a unique deposit address
                nonce: GatewayJS.utils.randomNonce(),

                // Arguments expected for calling `deposit`
                contractParams: [
                    { name: "_tokenSymbol", type: "string", value: "BTC" },
                    {
                        name: "_msg",
                        type: "bytes",
                        value: web3.eth.abi.encodeParameters(["address"], [renBTC.options.address]),
                    }
                ],

                // Web3 provider for submitting mint to Ethereum
                web3Provider: web3.currentProvider,
            }).result();
            this.log(`Deposited ${amount} BTC.`);
        } catch (error) {
            // Handle error
            this.logError(error);
        }
    }

    recoverTransfers = async () => {
        const { web3, gateway } = this.props;
        if (!gateway || !web3) {
            this.setState({ errorMsg: "Please enable Web3 or download the MetaMask extension" });
            return;
        }

        // Load previous transfers from local storage
        const previousGateways = await gateway.getGateways();
        // Resume each transfer
        for (const transfer of Array.from(previousGateways.values())) {
            gateway
                .recoverTransfer(web3.givenProvider, transfer)
                .pause()
                .result()
                .catch(this.logError);
        }
    }

    deposit = async (renAdapter: Contract, controller: Contract, swap: Contract, wBTC: Contract, renBTC: Contract, value: number, coinType: Token) => {
        try {
            switch (coinType) {
                case Token.BTC:
                    await this.depositBtc(renAdapter, value);
                    break;
                case Token.WBTC:
                    await this.depositERC20(controller, swap, wBTC, value);
                    break;
                case Token.RenBTC:
                    await this.depositERC20(controller, swap, renBTC, value);
                    break;    
                default:
                    this.setState({ errorMsg: `unsupported coin type ${coinType}` });
                    break;
            }
        } catch (err) {
            console.error(err);
        }
    }

    depositERC20 = async (controller: Contract, swap: Contract, erc20: Contract, amount: number) => {
        const { address } = this.props;

        const reserve = await controller.methods.reserve().call();
        const allowance = await erc20.methods.allowance(address, reserve).call();

        if (allowance < amount) {
            this.setState({ modalText: PendingApprovalText });
            await erc20.methods.approve(reserve, amount.toString()).send({ from: address });
        }
        this.setState({ modalText: PendingSubmissionText });
        await swap.methods.deposit(erc20.options.address, amount.toString()).send({ from: address });
    }

    depositBtc = async (renAdapter: Contract, amount: number) => {
        const { web3, gateway } = this.props;
        if (!web3 || !gateway) {
            this.setState({ errorMsg: "Please enable Web3 or download the MetaMask extension" });
            return;
        }

        try {
            await gateway.open({
                // Send BTC from the Bitcoin blockchain to the Ethereum blockchain.
                sendToken: GatewayJS.Tokens.BTC.Btc2Eth,

                // Amount of BTC we are sending (in Satoshis)
                suggestedAmount: amount, // Convert to Satoshis

                // The contract we want to interact with
                sendTo: renAdapter.options.address,

                // The name of the function we want to call
                contractFn: "deposit",

                // The nonce is used to guarantee a unique deposit address
                nonce: GatewayJS.utils.randomNonce(),

                // Arguments expected for calling `deposit`
                contractParams: [
                    { name: "_tokenSymbol", type: "string", value: "BTC" },
                    {
                        name: "_msg",
                        type: "bytes",
                        value: "0x" + new Buffer(`Convert ${amount} BTC to DittoBTC`).toString("hex"),
                    }
                ],

                // Web3 provider for submitting mint to Ethereum
                web3Provider: web3.currentProvider,
            }).result();
            this.log(`Deposited ${amount} BTC.`);
        } catch (error) {
            // Handle error
            this.logError(error);
        }
    }

    withdraw = async (renAdapter: Contract, dittoBTC: Contract, swap: Contract, wBTC: Contract, renBTC:Contract, toBtcAddress: string, value: number, coinType: Token) => {
        switch (coinType) {
            case Token.BTC:
                await this.withdrawBTC(renAdapter, dittoBTC, toBtcAddress, value);
                break;
            case Token.WBTC:
                await this.withdrawERC20(swap, wBTC, dittoBTC, value);
                break;
            case Token.RenBTC:
                await this.withdrawERC20(swap, renBTC, dittoBTC, value); 
                break;
            default:
                this.setState({ errorMsg: `Unsupported coin type ${coinType}` });
                break;
        }
    }

    withdrawBTC = async (renAdapter: Contract, dittoBTC: Contract, toBtcAddress: string, amount: number) => {
        const { web3, gateway, address } = this.props;
        if (!web3 || !gateway) {
            this.setState({ errorMsg: "Please enable Web3 or download the MetaMask extension" });
            return;
        }

        const allowance = await dittoBTC.methods.allowance(address, renAdapter.options.address).call();
        if (allowance < amount) {
            this.setState({ modalText: PendingApprovalText });
            await dittoBTC.methods.approve(renAdapter.options.address, amount.toString()).send({ from: address });
        }

        // You can surround shiftOut with a try/catch to handle errors.
        await gateway.open({
            // Send BTC from the Ethereum blockchain to the Bitcoin blockchain.
            // This is the reverse of shitIn.
            sendToken: GatewayJS.Tokens.BTC.Eth2Btc,

            // The contract we want to interact with
            sendTo: renAdapter.options.address,

            // The name of the function we want to call
            contractFn: "withdraw",

            // Arguments expected for calling `deposit`
            contractParams: [
                { name: "_tokenSymbol", type: "string", value: "BTC" },
                { name: "_to", type: "bytes", value: "0x" + Buffer.from(toBtcAddress).toString("hex") },
                { name: "_amount", type: "uint256", value: Math.floor(amount) },
            ],

            // Web3 provider for submitting burn to Ethereum
            web3Provider: web3.currentProvider,
        }).result();

        this.log(`Withdrew ${amount} BTC to ${toBtcAddress}.`);
    }

    withdrawERC20 = async (swap: Contract, erc20: Contract, dittoBTC: Contract, amount: number) => {
        const { address } = this.props;

        const allowance = await dittoBTC.methods.allowance(address, swap.options.address).call();
        if (allowance < amount) {
            this.setState({ modalText: PendingApprovalText });
            await dittoBTC.methods.approve(swap.options.address, amount.toString()).send({ from: address });
        }

        this.setState({ modalText: PendingSubmissionText });
        await swap.methods.withdraw(erc20.options.address, amount.toString()).send({ from: address });
    }

    transfer = async (wBTC: Contract, renBTC: Contract, dittoBTC: Contract, to: string, amount: number, coinType: Token) => {
        const { address } = this.props;
        switch (coinType) {
            case Token.WBTC:
                this.setState({ modalText: PendingSubmissionText });
                await wBTC.methods.transfer(to, amount.toString()).send({ from: address });
                break;
            case Token.RenBTC:
                this.setState({ modalText: PendingSubmissionText });
                await renBTC.methods.transfer(to, amount.toString()).send({ from: address });
                break;
            case Token.DittoBTC:
                this.setState({ modalText: PendingSubmissionText });
                await dittoBTC.methods.transfer(to, amount.toString()).send({ from: address });
                break;
            default:
                this.setState({ errorMsg: `Unsupported coin type ${coinType}` });
                break;
        }
    }

    log = async (message: string) => {
        console.log(message);
    }

    logError = async (message: string) => {
        console.error(message);
    }
}

export default Transform;
