import { BigNumber } from "bignumber.js";

/**
 * Enumerates all ditos and token forms. This is useful for accessing
 * functions that work on both types of tokens.
 */
export enum Token {
    DittoBTC,
    WBTC,
    BTC,
    RenBTC,
    DPT,
}

/**
 * Enumerates all of the dittos that this frontend supports. 
 */
export enum Ditto {
    DittoBTC,
};

/**
 * Enumerates all of the token forms that this frontend supports for
 * depositing/withdrawing to/from the transformer. 
 */
export enum TokenForm {
    WBTC,
    RenBTC,
    BTC,
};

/**
 * Enumerates all tokens as values that can be iterated over. This is needed
 * because an enumerated type cannot be iterated over, and sometimes we want to
 * run some logic for each supported token.
 */
export const Tokens = [Token.DittoBTC, Token.WBTC, Token.BTC, Token.DPT, Token.RenBTC];

/**
 * Enumerates all dittos as values that can be iterated over. This is needed
 * because an enumerated type cannot be iterated over, and sometimes we want to
 * run some logic for each supported ditto.
 */
export const Dittos = [Ditto.DittoBTC];

/**
 * Enumerates all token forms as values that can be iterated over. This is
 * needed because an enumerated type cannot be iterated over, and sometimes we
 * want to run some logic for each supported token form.
 */
export const TokenForms = [TokenForm.WBTC, TokenForm.BTC, TokenForm.RenBTC];

/**
 * 
 * @param token
 * 
 * @returns the number of decimals in this token.
 */
export function tokenDecimals(token: Token): number {
    switch (token) {
        case Token.DittoBTC:
        case Token.WBTC:
        case Token.BTC:
        case Token.DPT:
        case Token.RenBTC:
            return 8;
        default:
            throw new TypeError("non-exhaustive pattern");
    }
}

/**
 *
 * @param token
 * @param balance
 *
 * @returns the token balance with correct decimals.
 */
export function tokenBalanceWithDecimals(token: Token, balance: BigNumber): BigNumber {
    const decimals = tokenDecimals(token)
    const value = balance.div(new BigNumber(10).pow(decimals));
    if (value.lt(1.0)) {
        return new BigNumber(value.toPrecision(4));
    }
    return new BigNumber(value.toFixed(4));
}

/**
 *
 * @param token
 * @param balance
 *
 * @returns the human-readable string for the token balance.
 */
export function displayTokenBalance(token: Token, balance: BigNumber): string {
    const value = tokenBalanceWithDecimals(token, balance)
    return value.toString();
}

/**
 *
 * @param token
 *
 * @returns the human-readable string for the token.
 */
export function tokenSymbol(token: Token): string {
    switch (token) {
        case Token.BTC:
            return tokenFormSymbol(TokenForm.BTC);
        case Token.WBTC:
            return tokenFormSymbol(TokenForm.WBTC);
        case Token.RenBTC:
            return tokenFormSymbol(TokenForm.RenBTC);    
        case Token.DittoBTC:
            return dittoSymbol(Ditto.DittoBTC);
        case Token.DPT:
            return "DPT";
        default:
            throw new TypeError("non-exhaustive pattern");
    }
}

/**
 * 
 * @param tokenForm
 *
 * @returns a human-readable string that represents the tokenForm.
 */
export function tokenFormSymbol(tokenForm: TokenForm): string {
    switch (tokenForm) {
        case TokenForm.BTC:
            return "BTC";
        case TokenForm.WBTC:
            return "WBTC";
        case TokenForm.RenBTC:
            return "RenBTC";
        default:
            throw new TypeError("non-exhaustive pattern");
    }
}

/**
 * 
 * @param ditto
 *
 * @returns a human-readable string that represents the ditto.
 */
export function dittoSymbol(ditto: Ditto): string {
    switch (ditto) {
        case Ditto.DittoBTC:
            return "DittoBTC";
        default:
            throw new TypeError("non-exhaustive pattern");
    }
}

/**
 * Unwrap a token into a Ditto.
 *
 * @param token that will be unwrapped.
 *
 * @returns the token represented as a ditto. It throws a TypeError if the
 * token is not actually a ditto.
 */
export function unwrapDitto(token: Token): Ditto {
    switch (token) {
        case Token.DittoBTC:
            return Ditto.DittoBTC;
        default:
            throw new TypeError("non-exhaustive pattern");
    }
}

/**
 * Wrap a ditto into a token.
 * 
 * @param ditto that will be wrapped.
 *
 * @returns the equivalent token.
 */
export function wrapDitto(ditto: Ditto): Token {
    switch (ditto) {
        case Ditto.DittoBTC:
            return Token.DittoBTC;
        default:
            throw new TypeError("non-exhaustive pattern");
    }
}

/**
 * Unwrap a token into a token form.
 *
 * @param token that will be unwrapped.
 *
 * @returns the token represented as a token form. It throws a TypeError
 * if the token is not actually a token form.
 */
export function unwrapTokenForm(token: Token): TokenForm {
    switch (token) {
        case Token.BTC:
            return TokenForm.BTC;
        case Token.WBTC:
            return TokenForm.WBTC;
        case Token.RenBTC:
            return TokenForm.RenBTC;    
        default:
            throw new TypeError("non-exhaustive pattern");
    }
}

/**
 * Wrap a token into a token form.
 *
 * @param tokenForm that will be wrapped.
 *
 * @returns the equivalent token.
 */
export function wrapTokenForm(tokenForm: TokenForm): Token {
    switch (tokenForm) {
        case TokenForm.BTC:
            return Token.BTC;
        case TokenForm.WBTC:
            return Token.WBTC;
        case TokenForm.RenBTC:
            return Token.RenBTC;
        default:
            throw new TypeError("non-exhaustive pattern");
    }
}

/**
 * Map token forms to the ditto that is minted when the token forms
 * are deposited into the pool. This is the same ditto that must
 * be burned in order to withdraw the token forms from the pool.
 *
 * @param tokenForm that will be mapped into the ditto.
 *
 * @returns the ditto.
 */
export function tokenFormToDitto(tokenForm: TokenForm): Ditto {
    switch (tokenForm) {
        case TokenForm.BTC:
            return Ditto.DittoBTC;
        case TokenForm.WBTC:
            return Ditto.DittoBTC;
        case TokenForm.RenBTC:
            return Ditto.DittoBTC;
        default:
            throw new TypeError("non-exhaustive pattern");
    }
}

/**
 * Map a ditto to all the token forms that can be deposited into the
 * pool in order to mint the ditto. These are the same token forms
 * that can be withdrawn from the pool when burning the ditto.
 *
 * @param ditto that will be mapped to the token forms.
 *
 * @returns the underlying token.
 */
export function dittoToTokenForm(ditto: Ditto): TokenForm[] {
    switch (ditto) {
        case Ditto.DittoBTC:
            return [TokenForm.BTC, TokenForm.WBTC, TokenForm.RenBTC];
        default:
            throw new TypeError("non-exhaustive pattern");
    }
}