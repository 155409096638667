import * as React from "react";
import * as ReactDOM from "react-dom";
import './styles/index.scss'

import App from "./components/App";

import { Provider } from "unstated";

import "./styles/index.scss";

ReactDOM.render(
    <Provider>
        <App />
    </Provider>,
    document.getElementById("root") as HTMLElement
);
