import React, { Component } from "react";
import { AppState } from "./App";
import { SelectTag } from "./SelectTag";
import { Token, displayTokenBalance } from "../lib/token";
import { ReactComponent as IconDPT } from "../styles/images/dpt.svg";
import { ContractType } from "../lib/contracts";
import BigNumber from "bignumber.js";

interface Props {
    web3: AppState["web3"];
    contracts: AppState["contracts"];
    address: AppState["address"];
}

interface State {
    disabled: boolean
    height: number;  // Used for calculating background height.
    dptBalance: string;
    dittoBTCBalance: string;
    dptClaimableBalance: string;
}

class Earn extends Component<Props, State> {
    private interval: NodeJS.Timeout | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            disabled: true,
            height: 0,
            dptBalance: "0.0",
            dittoBTCBalance: "0.0",
            dptClaimableBalance: "0.0",
        }
        this.interval = null;
    }

    async componentDidMount() {
        this.setState({ height: document.body.scrollHeight });

        await this.updateBalances();
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.props.contracts && this.props.address && (!prevProps.contracts || !prevProps.address)) {
            await this.updateBalances();
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { disabled, height, dptBalance, dittoBTCBalance, dptClaimableBalance } = this.state;
        return (
            <div className="earn">
                <div className="background" style={{ height }}></div>
                <div className="container">
                    <div className="about">
                        <div className="info">
                            <h2>Earn a right to vote by holding dittoBTC</h2>
                            <p>Decide the future of the protocol with DPT, the Ditto Protocol Token. Simply hold dittoBTC and your DPT balance will begin to accrue.</p>
                        </div>
                    </div>
                    <div className="balance">
                        <span>Votes</span>
                        <p><IconDPT /> {dptBalance} DPT</p>
                    </div>
                    <div className="swapper">
                        <div className="bg">
                            <div className="input-wrapper">
                                <span>Balance</span>
                                <input className="input" type="text" value={dittoBTCBalance} disabled={true} />
                            </div>
                            <SelectTag changeValue={() => null} defaultOption={Token.DittoBTC} disabled hideDropdown />
                        </div>
                        <div className="bg">
                            <div className="input-wrapper">
                                <span>Claimable</span>
                                <input className="input" type="text" value={dptClaimableBalance} disabled={true} />
                            </div>
                            <SelectTag changeValue={() => null} defaultOption={Token.DPT} disabled hideDropdown />
                        </div>
                        <button disabled={disabled} onClick={this.collectRewards}>Collect</button>
                    </div>
                </div>
            </div>
        )
    }

    updateBalances = async () => {
        try {
            await this.updateDPTBalance();
            await this.updateDittoBTCBalance();
            await this.updateClaimableDPTBalance();
        } catch (error) {
            console.error(error);
        }

        this.interval = setInterval(async () => {
            await this.updateDPTBalance();
            await this.updateClaimableDPTBalance();
            await this.updateDittoBTCBalance();
        }, 10 * 1000);
    }

    collectRewards = async () => {
        const { contracts, address } = this.props;
        if (!contracts || !address) {
            return;
        }

        const dittoBTC = contracts.get(ContractType.DittoBTC);
        if (!dittoBTC) {
            return;
        }
        await dittoBTC.methods.transfer(address, 0).send({ from: address });
    }

    updateClaimableDPTBalance = async () => {
        const { contracts, address } = this.props;
        if (!contracts || !address) {
            return;
        }

        const dittoBTC = contracts.get(ContractType.DittoBTC);
        if (!dittoBTC) {
            return;
        }
        const balance = new BigNumber(await dittoBTC.methods.claimableStakeIncentiveOf(address).call());
        this.setState({ disabled: balance.eq(0), dptClaimableBalance: balance.eq(0) ? "0.0" : displayTokenBalance(Token.DPT, balance) });
    }

    updateDPTBalance = async () => {
        const { contracts, address } = this.props;
        if (!contracts || !address) {
            return;
        }

        const dpt = contracts.get(ContractType.DittoProtocolToken);
        if (!dpt) {
            return;
        }
        const balance = new BigNumber(await dpt.methods.balanceOf(address).call());
        this.setState({ dptBalance: balance.eq(0) ? "0.0" : displayTokenBalance(Token.DPT, balance) });
    }

    updateDittoBTCBalance = async () => {
        const { contracts, address } = this.props;
        if (!contracts || !address) {
            return;
        }

        const dittoBTC = contracts.get(ContractType.DittoBTC);
        if (!dittoBTC) {
            return;
        }
        const balance = new BigNumber(await dittoBTC.methods.balanceOf(address).call());
        this.setState({ dittoBTCBalance: balance.eq(0) ? "0.0" : displayTokenBalance(Token.DittoBTC, balance) });
    }
}

export default Earn;
