import React, { Component } from 'react'
// import githublogo from '../styles/images/githublogo.svg'
// import emaillogo from '../styles/images/emaillogo.svg'

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="container">
                    <p>&copy; 2020 Aksara</p>
                    <div className="links">
                        <a href="https://github.com/ditto-finance" target="_blank" rel="noopener noreferrer">GitHub</a>
                        <a href="mailto:ditto@aksara.works">Email</a>
                        <a href="https://t.me/dittofinance">Telegram</a>
                        <a href="https://twitter.com/dittofinance">Twitter</a>
                        <a href="https://medium.com/@dittofinance">Medium</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
