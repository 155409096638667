import Web3 from "web3";
import Config from "./config.json";
import { Token } from "./token";

export enum ContractType {
    RenBTC = "RenBTC",
    WBTC = "WBTC",
    DittoBTC = "DittoBTC",
    Transformer = "Transformer",
    Controller = "Controller",
    RenAdapter = "RenAdapter",
    DittoProtocolToken = "DittoProtocolToken",
    Reserve = "Reserve",
    FeeCollector = "FeeCollector",
}

const Contracts = [ContractType.RenBTC, ContractType.WBTC, ContractType.DittoBTC, ContractType.RenAdapter, ContractType.DittoProtocolToken, ContractType.Transformer, ContractType.Controller, ContractType.Reserve, ContractType.FeeCollector]

export async function getContracts(provider: Web3) {
    const netName = await provider.eth.net.getNetworkType();
    const contracts = new Map<ContractType, any>();
    const accounts = provider.eth.getAccounts();

    const network = netName === "unknown" ? "development" : netName;
    Contracts.forEach(ctype => {
        const artifact = (Config as any)[network][ctype];
        console.log(ctype, artifact.Address);
        console.log(ctype, artifact.ABI)
        return contracts.set(ctype, new provider.eth.Contract(artifact.ABI, artifact.Address, { from: accounts[0] }));
    });
    return contracts;
}

export function getContractAddress(token: Token): string {
    const artifact = (Config as any)["kovan"][tokenContractType(token)];
    return artifact.Address;
}

/**
 *
 * @param token
 *
 * @returns the contract type of the token.
 */
export function tokenContractType(token: Token): ContractType {
    switch (token) {
        case Token.BTC:
        case Token.RenBTC:
            return ContractType.RenBTC;
        case Token.WBTC:
            return ContractType.WBTC;
        case Token.DittoBTC:
            return ContractType.DittoBTC;
        default:
            throw new TypeError("non-exhaustive pattern");
    }
}
