import * as React from "react";

import Header from "./Header";
import Transform from "./Transform";
import Earn from "./Earn";
import Footer from "./Footer";

import Web3 from "web3";
import { getHttpProvider } from "../lib/web3";
import GatewayJS from "@renproject/gateway";
import { BrowserRouter, Route } from "react-router-dom";
import { getContracts, ContractType } from "../lib/contracts";
import { Contract } from "web3-eth-contract";

export interface AppState {
    web3: Web3 | null;
    contracts: Map<ContractType, Contract> | null;
    gateway: GatewayJS | null;
    address: string | null;
    errorMsg: string;
}

class App extends React.Component {
    state: AppState = {
        web3: null,
        contracts: null,
        gateway: null,
        errorMsg: "",
        address: null
    }

    async componentDidMount() {
        await this.initWeb3();
    }

    render(): JSX.Element {
        const { web3, contracts, address, gateway, errorMsg } = this.state;
        return (
            <div className="app">
                <BrowserRouter basename="/">
                    <Header errorMsg={errorMsg} address={address} initWeb3={this.initWeb3} />
                    <Route
                        path="/"
                        exact
                        render={() => <Transform web3={web3} contracts={contracts} gateway={gateway} address={address} />}
                    />
                    <Route
                        path="/earn"
                        render={() => <Earn web3={web3} contracts={contracts} address={address} />}
                    />
                    <Footer />
                </BrowserRouter>
            </div >
        );
    }

    initWeb3 = async () => {
        const web3 = await getHttpProvider();
        const netID = await web3.eth.net.getId();

        let gateway;
        switch (netID) {
            case 1:
                gateway = new GatewayJS("mainnet");
                break;
            case 42:
                gateway = new GatewayJS("testnet");
                break;
            default:
                this.setState({ errorMsg: "Please connect to Mainnet or Kovan on your dapp browser" });
                return;
        }
        const accounts = await web3.eth.getAccounts();

        // Initialise contracts.
        const contracts = await getContracts(web3);

        this.setState({ web3, contracts, gateway, address: accounts[0] });
    }
}

export default App;
