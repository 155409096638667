import React from 'react'
import { AppState } from "./App";

import { ReactComponent as Logo } from "../styles/images/logo.svg";
import { NavLink } from 'react-router-dom';

interface Props {
    address: AppState["address"];
    errorMsg: AppState["errorMsg"];
    initWeb3: () => void;
}

class Header extends React.Component<Props> {
    render(): JSX.Element {
        const { address, errorMsg, initWeb3 } = this.props;

        return (
            <div className="header">
                {errorMsg &&
                    <p className="warning">{errorMsg}</p>
                }
                <div className="container">
                    <Logo className="logo" />
                    <div className="links">
                        <NavLink exact to="/">Transform</NavLink>
                        <NavLink to="/earn">Earn</NavLink>
                    </div>
                    {address ?
                        <div className="address">{address.slice(0, 6)}...{address.slice(-4)}</div>
                        :
                        <button onClick={initWeb3}>Connect</button>
                    }
                </div>
            </div>
        );
    }
}

export default Header;