import React from 'react'
import { SelectTag } from './SelectTag'
import arrow from '../styles/images/arrow.svg'
import { Token, displayTokenBalance } from "../lib/token";
import { getContractAddress, ContractType } from "../lib/contracts";
import { ReactComponent as IconDPT } from "../styles/images/dpt.svg";
import { AppState } from './App';
import BigNumber from "bignumber.js";

interface Props {
    disabled: boolean
    contracts: AppState["contracts"];
    onSwap: (sendToken: Token, receiveToken: Token, amount: string, receiveBtcAddress: string) => void
    resetError: () => void;
}

interface State {
    sendToken: Token,
    receiveToken: Token,
    receiveReserveBalance: BigNumber,
    sendReserveBalance: BigNumber,
    receiveBtcAddress: string,
    rebalanceBonus: string,
    sendAmount: string,
    receiveAmount: string,
    showBtcAddressEntry: boolean,
}

class Swapper extends React.Component<Props, State> {
    constructor(props: Props, context: object) {
        super(props, context);
        this.state = {
            sendToken: Token.BTC,
            receiveToken: Token.DittoBTC,
            receiveReserveBalance: new BigNumber(0),
            sendReserveBalance: new BigNumber(0),
            rebalanceBonus: "0",
            receiveBtcAddress: "",
            sendAmount: "",
            receiveAmount: "",
            showBtcAddressEntry: false,
        };
    }

    componentDidMount = async () => {
        await this.updateReceiveReserveBalance();
        await this.updateSendReserveBalance();

        setInterval(async () => {
            await this.updateReceiveReserveBalance();
            await this.updateSendReserveBalance();
        }, 1000);
    }

    updateReceiveReserveBalance = async () => {
        const { contracts } = this.props;
        const { receiveToken } = this.state;
        const reserve = contracts?.get(ContractType.Reserve);
        const receiveReserveBalance = new BigNumber(await reserve?.methods.tokenFormBalance(getContractAddress(receiveToken)).call());
        this.setState({ receiveReserveBalance });
    }

    updateSendReserveBalance = async () => {
        const { contracts } = this.props;
        const { sendToken } = this.state;
        const reserve = contracts?.get(ContractType.Reserve);
        const sendReserveBalance = new BigNumber(await reserve?.methods.tokenFormBalance(getContractAddress(sendToken)).call());
        this.setState({ sendReserveBalance });
    }

    changeSendToken = (sendToken: Token) => {
        const { receiveToken, sendAmount } = this.state;
        this.setState({ sendToken, receiveAmount: this.calcReceiveAmount(sendToken, receiveToken, sendAmount) });
        this.props.resetError();
    }

    changeReceiveToken = (receiveToken: Token) => {
        const { sendToken, sendAmount } = this.state;
        this.setState({ receiveToken, showBtcAddressEntry: receiveToken === Token.BTC, receiveAmount: this.calcReceiveAmount(sendToken, receiveToken, sendAmount) });
        this.props.resetError();
    }

    swapTokens = () => {
        const { sendToken, receiveToken } = this.state;
        this.setState({ sendToken: receiveToken, receiveToken: sendToken, showBtcAddressEntry: sendToken === Token.BTC });
        this.props.resetError();
    }

    handleSendAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { sendToken, receiveToken, receiveReserveBalance } = this.state;
        const sendAmount = event.target.value;
        const receiveAmount = this.calcReceiveAmount(sendToken, receiveToken, sendAmount)

        const rcvAmount = new BigNumber(Math.floor(parseFloat(receiveAmount) * (10 ** 8)))

        if ((receiveToken === Token.BTC || receiveToken === Token.WBTC || receiveToken === Token.RenBTC)
            && rcvAmount.gt(receiveReserveBalance)) {
            // TODO: handle the send amount too high error.
        }
        const rebalanceBonus = this.updateRebalanceBonus(sendAmount, sendToken, receiveToken);
        this.setState({ sendAmount, receiveAmount, rebalanceBonus });
    }

    updateRebalanceBonus = (sendAmount: string, sendToken: Token, receiveToken: Token): string => {
        if (sendToken === Token.DittoBTC || receiveToken === Token.DittoBTC) {
            return "0";
        }
        const { sendReserveBalance, receiveReserveBalance } = this.state;

        const sendAmountF = parseFloat(sendAmount)
        if (isNaN(sendAmountF) || sendAmountF === 0) {
            return "0";
        }

        const sendAmountWithDecimals = Math.floor(sendAmountF * (10 ** 8));
        if (isNaN(sendAmountWithDecimals) || receiveReserveBalance.minus(sendReserveBalance.plus(sendAmountWithDecimals)).isNegative()) {
            return "0";
        }
        const transformIncentive = (receiveReserveBalance.minus(sendReserveBalance.plus(sendAmountWithDecimals)).times(sendAmountWithDecimals)).sqrt().times(1000);
        return displayTokenBalance(sendToken, new BigNumber(transformIncentive));
    }

    removeOrAddFees = (sendToken: Token, receiveToken: Token, amount: number, addFee: boolean): string => {
        let feeFixed: number;
        let feeVar: number;

        if (sendToken === Token.BTC && receiveToken === Token.DittoBTC) {
            feeFixed = 3500;
            feeVar = 1;
        } else if (sendToken === Token.BTC && receiveToken === Token.WBTC) {
            feeFixed = 3500;
            feeVar = 2;
        } else if (sendToken === Token.WBTC && receiveToken === Token.BTC) {
            feeFixed = 3500;
            feeVar = 2;
        } else if (sendToken === Token.DittoBTC && receiveToken === Token.BTC) {
            feeFixed = 3500;
            feeVar = 2;
        } else if ((sendToken === Token.DittoBTC && receiveToken === Token.WBTC)
            || (sendToken === Token.DittoBTC && receiveToken === Token.RenBTC)
            || (sendToken === Token.WBTC && receiveToken === Token.RenBTC)
            || (sendToken === Token.RenBTC && receiveToken === Token.WBTC)) {
            feeVar = 1;
            feeFixed = 0;
        } else {
            feeVar = 0;
            feeFixed = 0;
        }

        let amtAfterFee;
        if (addFee) {
            amtAfterFee = Math.floor(amount * (10 ** 8) * (1000 - feeVar) / 1000) - feeFixed;
        } else {
            amtAfterFee = Math.floor(amount * (10 ** 8) * 1000 / (1000 - feeVar)) + feeFixed;
        }

        if (!amtAfterFee || amtAfterFee < 0) {
            return "0.0"
        } else {
            return (amtAfterFee / (10 ** 8)).toString();
        }
    }

    calcReceiveAmount = (sendToken: Token, receiveToken: Token, sendAmount: string): string => {
        return this.removeOrAddFees(sendToken, receiveToken, parseFloat(sendAmount), true)
    }

    handleRecieveAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        const receiveAmount = event.target.value;
        const { sendToken, receiveToken } = this.state;
        const sendAmount = this.calcSendAmount(sendToken, receiveToken, receiveAmount);
        this.setState({ sendAmount, receiveAmount });
    }

    calcSendAmount = (sendToken: Token, receiveToken: Token, receiveAmount: string): string => {
        return this.removeOrAddFees(sendToken, receiveToken, parseFloat(receiveAmount), false)
    }

    handleBtcAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ receiveBtcAddress: event.target.value });
    }

    setSwapDetails = () => {
        const { onSwap } = this.props;
        const { sendToken, receiveToken, sendAmount, receiveBtcAddress } = this.state;
        onSwap(sendToken, receiveToken, sendAmount, receiveBtcAddress);
    }

    render(): JSX.Element {
        const { sendToken, sendAmount, receiveToken, receiveAmount, showBtcAddressEntry, rebalanceBonus } = this.state;
        const { disabled } = this.props;

        let removeTokensForSendSelectTag = [receiveToken]
        let removeTokensForReceiveSelectTag = [sendToken]
        if (sendToken === Token.BTC) {
            removeTokensForReceiveSelectTag = [...removeTokensForReceiveSelectTag, Token.RenBTC]
        }
        if (receiveToken === Token.BTC) {
            removeTokensForSendSelectTag = [...removeTokensForSendSelectTag, Token.RenBTC]
        }
        if (sendToken === Token.RenBTC) {
            removeTokensForReceiveSelectTag = [...removeTokensForReceiveSelectTag, Token.BTC]
        }
        if (receiveToken === Token.RenBTC) {
            removeTokensForSendSelectTag = [...removeTokensForSendSelectTag, Token.BTC]
        }

        return (
            <>
                <div className="bg">
                    <div className="input-wrapper">
                        <span>From</span>
                        <input className="input" type="text" placeholder="0.0" value={sendAmount} onChange={this.handleSendAmount} disabled={disabled} />
                    </div>
                    <SelectTag changeValue={this.changeSendToken} defaultOption={sendToken} removeTokens={removeTokensForSendSelectTag} disabled={disabled} />
                </div>
                <div className="arrow">
                    <img src={arrow} alt="arrow" onClick={this.swapTokens} />
                </div>
                <div className="bg">
                    <div className="input-wrapper">
                        <span>To</span>
                        <input className="input" type="text" placeholder="0.0" value={receiveAmount} onChange={this.handleRecieveAmount} disabled={disabled} />
                    </div>
                    <SelectTag changeValue={this.changeReceiveToken} defaultOption={receiveToken} removeTokens={removeTokensForReceiveSelectTag} disabled={disabled} />
                </div>
                <p className="fee">Inclusive of all fees</p>
                <p className="bonus"><IconDPT />+{rebalanceBonus} DPT rebalancing bonus</p>
                {
                    showBtcAddressEntry && !disabled &&
                    <input className="address" type="text" placeholder="Enter your Bitcoin address to receive your funds" onChange={this.handleBtcAddress} />
                }
                <button disabled={disabled} onClick={this.setSwapDetails}>Transform</button>
            </>
        );
    }
}

export default Swapper;
