import * as React from "react";

import Select, { components, OptionProps, SingleValueProps, ValueType } from "react-select";

import { ReactComponent as IconBTC } from "../styles/images/btc.svg";
import { ReactComponent as IconRenBTC } from "../styles/images/renBTC.svg";
import { ReactComponent as IconWBTC } from "../styles/images/wbtc.svg";
import { ReactComponent as IconDittoBTC } from "../styles/images/dittobtc.svg";
import { ReactComponent as IconDPT } from "../styles/images/dpt.svg";
import { Token } from "../lib/token"
import { SelectComponents } from "react-select/src/components";

interface Props {
    changeValue: (token: Token) => void;
    removeTokens?: Token[];
    defaultOption: Token;
    disabled?: boolean;
    hideDropdown?: boolean;
}

interface State {
}

const options = [
    { value: Token.BTC, label: "BTC", icon: <IconBTC /> },
    { value: Token.WBTC, label: "WBTC", icon: <IconWBTC /> },
    { value: Token.RenBTC, label: "RenBTC", icon: <IconRenBTC /> },
    { value: Token.DittoBTC, label: "dittoBTC", icon: <IconDittoBTC /> },
    { value: Token.DPT, label: "DPT", icon: <IconDPT />, exclude: true },
];

export class SelectTag extends React.Component<Props, State> {
    constructor(props: Props, context: object) {
        super(props, context);
        this.state = {
        };
        this.handleChange = this.handleChange.bind(this);
    }

    public render(): JSX.Element {
        const { defaultOption, removeTokens, disabled, hideDropdown } = this.props;

        let selectedOption: ValueType<any>;
        switch (defaultOption) {
            case Token.BTC:
                selectedOption = options[0];
                break;
            case Token.WBTC:
                selectedOption = options[1];
                break;
            case Token.RenBTC:
                selectedOption = options[2];
                break;
            case Token.DittoBTC:
                selectedOption = options[3];
                break;
            case Token.DPT:
                selectedOption = options[4];
                break;
        }

        const selectOptions: ValueType<any>[] = [];
        options.forEach((option) => {
            if (!option.exclude && removeTokens && !removeTokens.includes(option.value)) {
                selectOptions.push(option);
            }    
        });

        let components: Partial<SelectComponents<any>> = {
            SingleValue: customSingleValue,
            Option: customOption,
        }
        if (hideDropdown) {
            components.DropdownIndicator = () => null;
        }

        return (
            <div className="select">
                <Select
                    isDisabled={disabled}
                    components={components}
                    value={selectedOption}
                    options={selectOptions}
                    clearIndicator={true}
                    isSearchable={false}
                    styles={customStyles}
                    onChange={this.handleChange}
                />
            </div>
        );
    }

    private handleChange(selected: ValueType<any>): void {
        this.setState({ selected });
        const { changeValue } = this.props;
        changeValue(selected.value);
    }
}

const customSingleValue = (props: SingleValueProps<any>) => {
    const { SingleValue } = components;
    return (
        <SingleValue {...props}>
            {props.data.icon &&
                <span className="icon">{props.data.icon}</span>
            }
            <span className="label">{props.data.label}</span>
        </SingleValue>
    );
};

const customOption = (props: OptionProps<any>) => {
    const { Option } = components;
    return (
        <Option {...props}>
            {props.data.icon &&
                <span className="icon">{props.data.icon}</span>
            }
            {props.data.label}
        </Option>
    );
};

const width = "170px";
const customStyles = {
    control: () => ({
        display: "flex",
        border: "none",
        height: "35px",
        width,
        cursor: "default",
    }),
    singleValue: (provided: React.CSSProperties) => ({
        ...provided,
        fontSize: "20px",
        color: "#121b23",
        display: "flex",
        alignItems: "center",
        right: 0,
    }),
    menu: (provided: React.CSSProperties) => ({
        ...provided,
        fontSize: "18px",
        display: "flex",
        width,
        marginTop: "20px",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.05)",
        borderRadius: "30px",
        cursor: "default",
    }),
    option: (provided: React.CSSProperties, state: OptionProps<any>) => ({
        display: "flex",
        alignItems: "center",
        height: "60px",
        width,
        padding: "0 15px",
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
};
